(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("i18next"));
	else if(typeof define === 'function' && define.amd)
		define("SESSION", ["i18next"], factory);
	else if(typeof exports === 'object')
		exports["SESSION"] = factory(require("i18next"));
	else
		root["SESSION"] = factory(root["i18next"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__661__) {
return 