import i18next from 'i18next';

var _ = function (...args) {
    if (args.length > 1 && typeof args[1] === "object") {
        return i18next.t(args[0], Object.assign({},{
            ns: 'sessionApi',
            postProcess: 'sprintf'
        }, args[1]));
    } else {
        return i18next.t(args[0], {
            ns: 'sessionApi',
            postProcess: 'sprintf',
            sprintf: args.slice(1)
        });
    }

};

let generateGUID = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        /* jshint bitwise:false */
        var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
};

export class Message {
    constructor(data) {
        if (typeof data === "object") {
            for(var field in data) {
                if(field === 'time') {
                    this[field] = new Date(data.time);
                } else {
                    this[field] = data[field];
                }
            }
        }
        this.time = this.time || new Date();
        this.id = this.id || generateGUID();
        this.type = this.type || "notification";
    }

    setType(type) {
        this.type = type;
    }

    setData(data) {
        this.data = data;
    }

    getData() {
        return this.data;
    }

    getType() {
        return this.type;
    }

    setTarget(target) {
        this.target = target;
    }

    getTaget() {
        return this.target;
    }

    setSource(source) {
        this.source = source;
    }

    getSource() {
        return this.source;
    }

    toString() {
        return JSON.stringify(this, function(key, value) {
            if (this instanceof Message) {
                // fix toJSON from prototypejs which add \"\" to the serialization
                if (key === 'time' && typeof this.time.toISOString === "function") {
                    return this.time.toISOString();
                }
            }
            return value;
        });
    }
}

class PingMessage extends Message {
    constructor(data) {
        super(data);
        this.type = "ping";
    }
}

class SubscribeMessage extends Message {
    constructor(data) {
        super(data);
        this.type = "subscribe";
    }
}


class UnsubscribeMessage extends Message {
    constructor(data) {
        super(data);
        this.type = "unsubscribe";
    }
}

class PublishMessage extends Message {
    constructor(topics, messageType) {
        super();
        this.type = "publish";
        this.topics = topics;
        this.messageType = messageType;
    }
}

export class SessionApi {
    constructor(endpointUrl, topics, name, version, baseURL) {
        this.endpointUrl = endpointUrl;
        this.name = name;
        this.version = version;
        this.topics = this.toTopics(topics);
        this.clientId = generateGUID();
        if(!localStorage.browserId) {
            localStorage.browserId = generateGUID();
        }
        this.browserId = localStorage.browserId;
        this.autoReconnect = true;
        this.retry = 0;
        this.listeners = {};
        this.baseURL = baseURL || '';
        this.activityInterval = undefined;
        this._internalResetTimer = this._resetTimer.bind(this);


    }

    toTopics(topics) {
        if(typeof(topics) === 'string') {
            // String is a single topic
            return [[topics]];
        } else if(typeof(topics) === 'object' && Array.isArray(topics)) {
            // Prevent a an array of value [topic1, topic2]
            for(var i=0; i < topics.length; i++) {
                if(!Array.isArray(topics[i])) {
                    topics[i] = [topics[i]];
                }
            }

        }
        return topics;
    }

    init() {
        if (this.retry === 0) {
            this._connect();
        } else if (this.retry <= 6) {
            setTimeout(() => {
                this._connect();
            }, 5**(this.retry-1) * 1000); // wait 1s, 5s, 25s, 2m5s, 10m25s, 52m5s
        } else {
            // Failed to connect
            this.triggerEvent('close');
        }
    }

    _connect() {
        this.retry++;
        this.ws = new WebSocket(this._url());

        this.ws.onopen = this._open.bind(this);
        this.ws.onmessage = this._message.bind(this);
        this.ws.onerror = this._error.bind(this);
        this.ws.onclose = this._close.bind(this);

        this._removePing();
        this.pingInterval = setInterval(function() {
            this.sendMessage(new PingMessage());
        }.bind(this), 240000);
    }

    _removePing() {
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }
    }

    _open(event) {
        // clear retry only if connection is opened for 30 secs
        let currentWs = this.ws;
        setTimeout(() => {
            if (this.ws === currentWs && this.isConnected()) {
                this.retry = 0;
            }
        }, 30000);

        this.triggerEvent("connect");
    }

    _internalProcessMessage(msgObj) {
        if(msgObj.type && msgObj.data && msgObj.data.clientId === this.clientId) {
            if (msgObj.type.toLowerCase() === "websessionstarted") {
                if(this.activityInterval) {
                    clearInterval(this.activityInterval);
                    this.activityInterval = undefined;
                    document.removeEventListener('mousemove',this._internalResetTimer);
                    document.removeEventListener('keydown', this._internalResetTimer);
                    document.removeEventListener('touchstart', this._internalResetTimer);
                }

                if(msgObj.data.timeout) {
                    this._internalResetTimer();
                    document.addEventListener('mousemove',this._internalResetTimer);
                    document.addEventListener('keydown', this._internalResetTimer);
                    document.addEventListener('touchstart', this._internalResetTimer);
                    this.activityInterval = setInterval(()=> {                        
                        if(Number(localStorage.lastActivity) + (msgObj.data.timeout * 1000 * 60) < Date.now()) {
                            this.close();
                            this.wipeScreen(_("Your session was inactive."));
                            fetch(this.baseURL + '/publicapi/login', {
                                method: 'DELETE',
                                credentials: 'include'
                            });        
                            clearInterval(this.activityInterval);
                        }
                    }, 1000); // Check timeouts ever seconds
                }
            }
            if (msgObj.type.toLowerCase() === "websessionterminate") {
                if(msgObj.data.logout) {
                    fetch(this.baseURL + '/publicapi/login', {
                        method: 'DELETE',
                        credentials: 'include'
                    });
                }
                this.close();
                var message = msgObj.data.message ? msgObj.data.message : undefined;
                if (!message) {
                    if(msgObj.data.code === 'admin-request') {
                        message = _("Your administrator remotely terminated your session.");
                    } else if(msgObj.data.code === 'cal-deleted') {
                        message = _("Your access has been revoked.");
                    } else if(msgObj.data.code === 'too-many-sessions') {
                        message = _("You have opened too many sessions.");
                    }
                }
                if(!message) {
                    message = _("Your session was remotely terminated.");
                }
                this.wipeScreen(message);
            }
            if (msgObj.type.toLowerCase() === "websessionreload") {
                window.location.reload();
            }
        }
    }

    _message(event) {
        try {
            var msg = JSON.parse(event.data);
            var msgObj = new Message(msg);
        } catch (e) {
            this.triggerEvent('error', _("Received an invalid message"));
        }
        this._internalProcessMessage(msgObj);
        this.triggerEvent(msgObj.getType().toLowerCase() + "Message", msgObj);
    }
    _resetTimer() {
        localStorage.lastActivity = Date.now();
    }

    _error(event) {
        this._removePing();
        if (this.autoReconnect) {
            this.init();
        } else {
            this.triggerEvent('error');
        }
    }

    _close(event) {
        this._removePing();
        if (this.autoReconnect) {
            this.init();
        } else {
            this.triggerEvent('close');
        }
    }

    _url() {
        var url = this.endpointUrl;
        url += '?clientId=' + encodeURI(this.clientId) + "&browserId=" + encodeURI(this.browserId);
        url += this.topics ? '&topics=' + encodeURI(JSON.stringify(this.topics)).replaceAll('%22', '') : '';
        url += this.name ? '&name=' + encodeURI(this.name) : '';
        url += this.version ? '&version=' + encodeURI(this.version) : '';

        return url;
    }

    isConnected() {
        return this.ws && this.ws.readyState === 1;
    }

    sendMessage(message) {
        if (this.isConnected()) {
            if (message instanceof Message) {
                this.ws.send(message.toString());
            } else {
                this.ws.send(message);
            }
        } else {
            this.triggerEvent('error', _("Not connected"));
        }
    }

    publish(topics, messageType, data) {
        var message = new PublishMessage(topics, messageType);
        message.setData(data);
        this.sendMessage(message);
    }

    addTopics(topics) {
        var message = new SubscribeMessage();
        message.setData({topics: this.toTopics(topics)});
        this.sendMessage(message);
    }

    removeTopics(topics) {
        var message = new UnsubscribeMessage();
        message.setData({topics: this.toTopics(topics)});
        this.sendMessage(message);
    }

    close() {
        this.autoReconnect = false;
        this.ws.close();
    }

    bind(messageType, fnct) {
        if(messageType) {
            messageType = messageType.toLowerCase();
        }
        if(this.listeners[messageType] === undefined) {
            this.listeners[messageType] = [];
        }
        this.listeners[messageType].push(fnct);
    }

    unbind(messageType, fnct) {
        if (!messageType && !fnct) {
            //clear all listeners
            this.listeners = {};
        } else if(!messageType) {
            let messages = Object.keys(this.listeners);
            messages.forEach((message)=> {
                let current = this.listeners[message];
                if(current) {
                    this.listeners[message] = current.filter((localfnct)=> fnct !== localfnct);
                }
            });
        } else if(!fnct) {
            delete this.listeners[messageType];
        } else {
            let current = this.listeners[messageType];
            if(current) {
                this.listeners[messageType] = current.filter((localfnct)=> fnct !== localfnct);
            }
        }
        console.log(this.listeners);
    }

    async waitMessage(messageType, delay=10000) {
        return new Promise((accept, reject) => {            
            let timeout = undefined;
            let receiveListener = (data) => {
                if(timeout) {
                    clearTimeout(timeout);
                }
                accept(data);
            };            

            timeout = setTimeout(() => {
                this.unbind(messageType, receiveListener);
                reject(new Error(`Timeout while waiting for ${messageType}`));
            }, delay);

            this.bind(messageType, receiveListener);
        });
    }

    triggerEvent(messageType, message) {
        if(messageType) {
            messageType = messageType.toLowerCase();
        }
        
        let listeners = this.listeners[messageType];
        if(listeners) {
            listeners.forEach(listener => {
                listener(messageType, message);
            });
        }
        listeners = this.listeners[undefined];
        if(listeners) {
            listeners.forEach(listener => {
                listener(messageType, message);
            });
        }
    }

    wipeScreen(message) {    
        // Wipe the page
        document.body.innerHTML = '';
        
        // Add the message
        var messageDiv = document.createElement('div');
        messageDiv.textContent = message;
        messageDiv.style.cssText='text-align:center;color:#12959f;padding-top:100px;font-size:60px';
        document.body.appendChild(messageDiv);

        //Add a reload button
        var reloadLink = document.createElement('a');
        reloadLink.textContent = _('Reload');
        reloadLink.href='';
        reloadLink.style.cssText='display:block;text-align:center;color:#12959f;padding-top:10px;font-size:16px';
        reloadLink.onclick = function () {
            document.location.reload();
        };

        document.body.appendChild(reloadLink);

    }
}

export function createSessionMessage(data) {
    return new Message(data);
}
